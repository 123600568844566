import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { FacebookShare, WhatsappShare } from "react-share-kit";

import { Layout, Row, Col, Tabs } from "antd";
import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";

import { GoogleAnalytics } from "../utils/index.js";

import * as actions from "../redux/actions";

import SERVICE from "../services";

import ProductPriceGraph from "../components/product/ProductPriceGraph";
import ProductPriceHistory from "../components/product/ProductPriceHistory";
import MatchedProducts from "../components/product/MatchedProducts";

import ButtonFavouriteProduct from "../components/product/ButtonFavouriteProduct";
import GoogleAdsenseResponsive from "../components/GoogleAdsenseResponsive";

const { Content } = Layout;
const { TabPane } = Tabs;

function PageProduct(props) {
  const [latestPriceFetched, setLatestPriceFetched] = useState(false);
  const { match, productInfo, menu, productLatestPriceRequestStatus } = props;
  const { coupons, images } = productInfo;
  const { params } = match;
  const { website, productId } = params;

  useEffect(() => {
    props.getProductRequest({
      website: website,
      productId: productId,
    });
    // reset on every product change
    setLatestPriceFetched(false);
  }, [website, productId]);

  let product = {};

  let name = productInfo.name || "";
  let image = productInfo.image || "";
  let pwebsite = productInfo.website || "";
  let price = productInfo.price || "";
  let currency = productInfo.currency || "";
  let url = productInfo.url || "";
  let productPrices = productInfo.productPrices || [];
  let matchedProducts = productInfo.matchedProducts || [];
  let viewCount = productInfo.viewCount || 0;
  let categoryId = productInfo.categoryId || false;

  let { priceMax, priceMin } = productInfo;

  let showPriceDiff = false;
  let priceDiffTextColor = "#2c531f";
  if (productInfo.priceDiff) {
    if (productInfo.priceDiff > 0) {
      priceDiffTextColor = "red";
      showPriceDiff = "Price up " + currency + productInfo.priceDiff;
    } else {
      showPriceDiff = "Price drop " + currency + productInfo.priceDiff;
    }
  }

  let webLogo =
    "https://cheapshops.in/api/static/images/websites_logo/" +
    pwebsite +
    ".png";

  let websiteStoreUrl = "/store/" + pwebsite;

  const trackBuyNowClick = () => {
    GoogleAnalytics.trackEvent({
      category: "BuyNowClick",
      action: website,
      label: price,
      value: url,
    });

    props.buyNowClickRequest({
      website: website,
      productId: productId,
      price: price,
    });
  };

  let metaTitle = "";
  if (name) {
    metaTitle = name;
  }
  if (pwebsite) {
    metaTitle += " Online at Best Price On " + pwebsite;
  }
  metaTitle += " price history: Cheapshops.in";

  let metaDescription = "Buy";
  if (name) {
    metaDescription += " " + name;
  }
  metaDescription += " online at best prices in India";
  if (pwebsite) {
    metaDescription += " from " + pwebsite;
  }
  metaDescription += " price history : Cheapshops.in";

  const onTabChange = () => {};

  useEffect(() => {
    if (
      latestPriceFetched === false &&
      productInfo &&
      productInfo.name &&
      productInfo.website
    ) {
      props.getProductLatestPriceRequest({
        website: website,
        productId: productId,
      });
      setLatestPriceFetched(true);
    }
  }, [productInfo, website, productId]);

  let showImages = [image];
  if (images && images.length > 0) {
    showImages = showImages.concat(images);
  }

  return (
    <>
      {productInfo && productInfo.name && productInfo.website ? (
        <div id="page-product">
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content={metaDescription} />
            <meta name="title" content={metaTitle} />
            <title>{metaTitle}</title>
            {/* <link rel="canonical" href="http://mysite.com/example" /> */}
          </Helmet>
          <HeaderMain />
          {/* <Content className="page-content"><Search /></Content> */}
          {/* <FooterMain /> */}
          <div className="page-content">
            {coupons && coupons.length > 0 ? (
              <Row>
                <Col
                  xs={24}
                  className="coupon-code-alert"
                  style={{ color: priceDiffTextColor }}
                >
                  {coupons.map((coupon) => {
                    const { code, description } = coupon;
                    return (
                      <>
                        <span className="description">{description}</span>
                        <span className="code">{code}</span>
                      </>
                    );
                  })}
                </Col>
              </Row>
            ) : null}
            {showPriceDiff ? (
              <Row>
                <Col
                  xs={24}
                  className="price-drop-alert"
                  style={{ color: priceDiffTextColor }}
                >
                  {showPriceDiff}
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col md={8} xl={8} sm={24} xs={24} className="main-product-image">
                <Carousel showArrows={false} showThumbs={false}>
                  {showImages && showImages.length > 0
                    ? showImages.map((moreImage) => {
                        return (
                          <div>
                            <img className="bigImage" src={moreImage} />
                          </div>
                        );
                      })
                    : null}
                </Carousel>
                <br />
                <Row>
                  <Col md={24} xl={24} sm={24} xs={24}>
                    <div className="view-count-product">
                      {viewCount && viewCount > 0 ? viewCount + " views" : null}
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  {/* <Col md={12} xl={12} sm={12} xs={12}>
                    <div className="view-count-product">
                      {viewCount && viewCount > 0 ? viewCount + " views" : null}
                    </div>
                  </Col> */}
                  <Col md={24} xl={24} sm={24} xs={24}>
                    <div className="user-favourite-block">
                      <ButtonFavouriteProduct
                        buttonType="button"
                        website={website}
                        productId={productId}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={16} xl={16} sm={24} xs={24} className="product-info">
                {productInfo?.categoryInfo?.categoryUrl ? (
                  <Link
                    to={productInfo.categoryInfo.categoryUrl}
                    className="category-url"
                  >
                    {productInfo.categoryInfo.categoryName}
                  </Link>
                ) : null}

                <div className="name">{name}</div>

                {productLatestPriceRequestStatus?.isLoading ? (
                  <Row>
                    <Col xs={24} className="price-fetch-alert ">
                      <span>fetching latest price...</span>
                    </Col>
                  </Row>
                ) : null}

                <Row>
                  <Col
                    md={8}
                    xl={4}
                    sm={10}
                    xs={10}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="price">
                      {currency}
                      {price}
                    </div>
                  </Col>
                  <Col
                    md={4}
                    xl={4}
                    sm={14}
                    xs={14}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <div className="website">
                      <Link to={websiteStoreUrl}>
                        <img src={webLogo} />
                      </Link>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={24} xl={24} sm={24} xs={24}>
                    <div className="buy-link">
                      <a
                        href={url}
                        // target="_BLANK"
                        onClick={trackBuyNowClick}
                      >
                        Buy Now
                      </a>
                    </div>
                  </Col>
                </Row>

                <Row>
                  {priceMin && priceMin > 0 ? (
                    <Col md={4} xl={4} sm={12} xs={12} className="minPrice">
                      Lowest Price {currency}
                      {priceMin}
                    </Col>
                  ) : null}
                  {priceMax && priceMax > 0 ? (
                    <Col md={4} xl={4} sm={12} xs={12} className="maxPrice">
                      Highest Price {currency}
                      {priceMax}
                    </Col>
                  ) : null}
                </Row>

                <Row style={{ marginTop: 5, marginBottom: 5 }}>
                  <Col md={24} xl={24} sm={24} xs={24}>
                    <FacebookShare size={24} url={url} title={name} />
                    <WhatsappShare
                      size={24}
                      url={url}
                      title={name}
                      style={{ marginLeft: 10 }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={24}>
                    <GoogleAdsenseResponsive />
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} className="product-price-graph">
                    {productPrices && productPrices.length > 0 ? (
                      <>
                        <Tabs
                          defaultActiveKey="1"
                          onChange={onTabChange}
                          type="card"
                          tabBarStyle={{ fontWeight: "bold" }}
                        >
                          <TabPane tab="Price Graph" key="1">
                            {/* <h3>Price Chart - {name}</h3>
                            <br /> */}
                            <ProductPriceGraph productPrices={productPrices} />
                          </TabPane>
                          <TabPane tab="Price History" key="2">
                            {/* <h3>Price History - {name}</h3>
                            <br /> */}
                            <ProductPriceHistory
                              productPrices={productPrices}
                            />
                          </TabPane>
                        </Tabs>
                      </>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xs={24}>
                <br />
                <GoogleAdsenseResponsive />
              </Col>
            </Row>
            {matchedProducts && matchedProducts.length > 0 ? (
              <Row>
                <Col xs={24} className="product-price-graph">
                  {matchedProducts && matchedProducts.length > 0 ? (
                    <>
                      <MatchedProducts products={matchedProducts} />
                    </>
                  ) : null}
                </Col>
              </Row>
            ) : null}

            {/* <WebsitesLoader websitesList={websites} /> */}
            {/* {recentProducts.map((product, index) => {
          return <ProductWebSearch data={product} key={index} />;
        })} */}
          </div>
          <FooterMain />
        </div>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  productInfo: state.product.product.data || {},
  menu: state.generic.menu.data || {},
  productLatestPriceRequestStatus:
    state.product.productLatestPriceRequestStatus || {},
});

const mapDispatchToProps = (dispatch) => ({
  getProductRequest: (data) => dispatch(actions.getProductRequest(data)),
  buyNowClickRequest: (data) => dispatch(actions.buyNowClickRequest(data)),
  getProductLatestPriceRequest: (data) =>
    dispatch(actions.getProductLatestPrice.request(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageProduct);
