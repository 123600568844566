const saveUserWebsitesToProcess = (arr) => {
  localStorage.setItem("WEBSITES_TO_PROCESS", JSON.stringify(arr));
};

const saveUserSearchCategoty = (data) => {
  localStorage.setItem("SEARCH_CATEGORY", JSON.stringify(data));
};

const getUserWebsitesToProcess = (arr) => {
  let tmp = localStorage.getItem("WEBSITES_TO_PROCESS");
  let tmpArr = [];
  try {
    tmpArr = JSON.parse(tmp);
  } catch (e) {
    tmpArr = [];
  }
  return tmpArr;
};

const getProductPageUrl = (product) => {
  let ret = false;
  let { name, price, image, url, website, productId } = product;
  if (name && website && productId) {
    name = name.replace(/[^A-Za-z0-9]/g, " ");
    name = name.replace(/  +/g, " ");
    if (name.length > 100) {
      name = name.substring(0, 100);
    }
    name = name.replace(new RegExp(" ", "g"), "-");
    let url = "/product/" + website + "/" + productId + "/" + name;
    ret = url;
  }
  return ret;
};

const sortProductsList = (products, sortOrder) => {
  let existingSortOrder = sortOrder.find((o) => o.selected === true);
  existingSortOrder = existingSortOrder.key;
  if (existingSortOrder === "priceHighToLow") {
    products.sort((a, b) => b.price - a.price);
  } else {
    products.sort((a, b) => a.price - b.price);
  }
  return products;
};

const setCountryCode = (data) => {
  localStorage.setItem("COUNTRY_CODE", data);
};

const getCountryCode = () => {
  return localStorage.getItem("COUNTRY_CODE") || "IN";
};

const getWebsiteLogo = (website) => {
  let url =
    "https://cheapshops.in/api/static/images/websites_logo/" + website + ".png";
  return url;
};

export {
  saveUserWebsitesToProcess,
  getUserWebsitesToProcess,
  getProductPageUrl,
  sortProductsList,
  saveUserSearchCategoty,
  setCountryCode,
  getCountryCode,
  getWebsiteLogo,
};
