import React from "react";
import { Statistic, Row, Col } from "antd";

class AdminStats extends React.Component {
  render() {
    let { stats } = this.props;
    return (
      <div style={{ marginTop: "60px" }}>
        <Row>
          <Col md={24} xl={24} sm={24} xs={24} style={{ padding: "10px" }}>
            <Row className="my-stats">
              <Col xl={4} md={4} xs={12}>
                <Statistic
                  title="Total Users"
                  value={stats?.users?.users_total || "--"}
                />
              </Col>
              <Col xl={4} md={4} xs={12}>
                <Statistic
                  title="Active Users"
                  value={stats?.users?.users_active || "--"}
                />
              </Col>
              <Col xl={4} md={4} xs={12}>
                <Statistic
                  title="Verified Email"
                  value={stats?.users?.users_isEmailVerified || "--"}
                />
              </Col>
              <Col xl={4} md={4} xs={12}>
                <Statistic
                  title="Not Verified Email"
                  value={stats?.users?.users_isEmailNotVerified || "--"}
                />
              </Col>
              <Col xl={4} md={4} xs={12}>
                <Statistic
                  title="Total Fav Products"
                  value={stats?.products?.products_favourite_total || "--"}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={24} xl={24} sm={24} xs={24} style={{ padding: "10px" }}>
            <Row className="my-stats">
              <Col xl={6} md={6} xs={12}>
                <Statistic
                  title="Total Products"
                  value={stats?.products?.products_total || "--"}
                />
              </Col>
              <Col xl={6} md={6} xs={12}>
                <Statistic
                  title="IN Products"
                  value={stats?.products?.products_total_IN || "--"}
                />
              </Col>
              <Col xl={6} md={6} xs={12}>
                <Statistic
                  title="UK Products"
                  value={stats?.products?.products_total_UK || "--"}
                />
              </Col>
              <Col xl={6} md={6} xs={12}>
                <Statistic
                  title="USA Products"
                  value={stats?.products?.products_total_USA || "--"}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row>
          <Col md={24} xl={24} sm={24} xs={24} style={{ padding: "10px" }}>
            <Row className="my-stats">
              <Col xl={6} md={6} xs={12}>
                <Statistic
                  title="Total Links"
                  value={stats.links_total || "--"}
                />
              </Col>
              <Col xl={6} md={6} xs={12}>
                <Statistic
                  title="Active Links"
                  value={stats.links_active || "--"}
                />
              </Col>
            </Row>
          </Col>
        </Row> */}
        {/* <Row>
          <Col md={24} xl={24} sm={24} xs={24} style={{ padding: "10px" }}>
            <Row className="my-stats">
              <Col xl={6} md={6} xs={12}>
                <Statistic
                  title="Overall Short Shout Impressions"
                  value={stats.total_overall_user_profile_impressions || "--"}
                />
              </Col>
              <Col xl={6} md={6} xs={12}>
                <Statistic
                  title="Over All Links Clicked"
                  value={stats.total_overall_links_click || "--"}
                />
              </Col>
            </Row>
          </Col>
        </Row> */}
      </div>
    );
  }
}

export default AdminStats;
