import React from "react";
import { connect } from "react-redux";
import { Select, Dropdown, Space } from "antd";
import ReactCountryFlag from "react-country-flag";

import {
  setCountryCode,
  getCountryCode,
  saveUserSearchCategoty,
  saveUserWebsitesToProcess,
} from "../utils/Generic";
const CountrySelection = (props) => {
  const { countriesList } = props;
  // console.log(countriesList);
  let selectedCountryCode = getCountryCode();
  console.log(selectedCountryCode);
  const handleChange = (e) => {
    saveUserSearchCategoty("");
    saveUserWebsitesToProcess("");
    setCountryCode(e.key);
    window.location.replace(process.env.REACT_APP_WEB_BASE_URL);
  };
  let items = [];
  countriesList.forEach((country) => {
    const { isActive, flagCode, name } = country;
    if (isActive) {
      items.push({
        key: country?.code,
        label: name,
        icon: (
          <ReactCountryFlag
            style={{ fontSize: 30 }}
            countryCode={flagCode}
            svg
          />
        ),
      });
    }
  });
  // for (let c in countriesList) {

  // }
  // const items = [
  //   {
  //     key: "IN",
  //     label: "India",
  //     icon: <ReactCountryFlag style={{ fontSize: 30 }} countryCode="IN" svg />,
  //   },
  //   {
  //     key: "UK",
  //     label: "UK",
  //     icon: <ReactCountryFlag style={{ fontSize: 30 }} countryCode="gb" svg />,
  //   },
  //   {
  //     key: "USA",
  //     label: "USA",
  //     icon: <ReactCountryFlag style={{ fontSize: 30 }} countryCode="us" svg />,
  //   },
  //   {
  //     key: "GERMANY",
  //     label: "GERMANY",
  //     icon: <ReactCountryFlag style={{ fontSize: 30 }} countryCode="de" svg />,
  //   },
  //   {
  //     key: "FRANCE",
  //     label: "FRANCE",
  //     icon: <ReactCountryFlag style={{ fontSize: 30 }} countryCode="fr" svg />,
  //   },
  // ];

  let selectedFlag = items.find((f) => f.key === selectedCountryCode);
  // console.log(selectedFlag);

  return (
    <>
      <Dropdown
        trigger="click"
        menu={{
          items,
          onClick: handleChange,
        }}
      >
        <a onClick={(e) => console.log(e.target.value)}>
          <Space>{selectedFlag?.icon}</Space>
          <span style={{ fontWeight: "bold" }}>{selectedFlag?.label}</span>
        </a>
      </Dropdown>
      {/* <Select
        bordered={false}
        defaultValue={selectedCountryCode}
        // style={{ width: 120 }}
        onChange={handleChange}
        options={[
          {
            value: "IN",
            label: "India",
          },
          {
            value: "UK",
            label: "UK",
          },
          {
            value: "USA",
            label: "USA",
          },
        ]}
      /> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  countriesList: state?.generic?.menu?.data?.countriesList || [],
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelection);
