import React from "react";
import { connect } from "react-redux";

import { Row, Col } from "antd";
import { Button } from "antd";
import { Link } from "react-router-dom";

import { isMobile } from "react-device-detect";

import * as actions from "../../redux/actions";

import { GoogleAnalytics, Generic } from "../../utils/index.js";

import ButtonFavouriteProduct from "./ButtonFavouriteProduct";

function TemplateProductSmallView(props) {
  const { data } = props;
  const {
    name,
    price,
    image,
    url,
    website,
    productId,
    viewCount,
    priceDiff,
    coupons,
    currency,
  } = data;
  let webLogo =
    "https://cheapshops.in/api/static/images/websites_logo/" + website + ".png";

  let showAddRemoveFavButton = false;
  if (website && website !== "" && productId && productId !== "") {
    showAddRemoveFavButton = true;
  }

  const trackBuyNowClick = () => {
    GoogleAnalytics.trackEvent({
      category: "BuyNowClick",
      action: website,
      label: price,
      value: url,
    });

    props.buyNowClickRequest({
      website: website,
      productId: productId,
      price: price,
    });
  };

  let productPageLink = Generic.getProductPageUrl(data);

  let showPriceDiff = false;
  let priceDiffTextColor = "#2c531f";
  if (priceDiff) {
    if (priceDiff > 0) {
      priceDiffTextColor = "red";
      showPriceDiff = "Price up " + currency + priceDiff;
    } else {
      showPriceDiff = "Price drop " + currency + priceDiff;
    }
  }

  // console.log(coupons);

  return (
    <Col xs={12} sm={12} md={8} lg={6}>
      <div className="template-product-small-view gutter-row">
        {coupons && coupons.length > 0 ? (
          <Row style={{ height: "5%" }}>
            <Col
              xs={24}
              className="coupon-code-alert"
              style={{ color: priceDiffTextColor }}
            >
              {coupons.map((coupon) => {
                const { code, description } = coupon;
                return (
                  <>
                    <span className="description">{description}</span>
                    <span className="code">{code}</span>
                  </>
                );
              })}
            </Col>
          </Row>
        ) : null}

        <Row style={{ height: "5%" }}>
          {showPriceDiff ? (
            <Col
              xs={24}
              className="price-drop-blinker"
              style={{ color: priceDiffTextColor }}
            >
              {showPriceDiff}
            </Col>
          ) : null}
        </Row>
        <Row style={{ height: "10%" }}>
          <Col xs={12}>
            <div className="product-view-count">
              {viewCount && viewCount > 0 ? viewCount + " views" : null}
            </div>
          </Col>
          <Col xs={12}>
            <div className="product-websitelogo">
              <img src={webLogo} alt={website} />
            </div>
          </Col>
        </Row>

        <div className="product-image" style={{ height: "40%" }}>
          <Link
            // target="_blank"
            to={productPageLink}
          >
            <img src={image} alt={name} />
          </Link>
        </div>
        <div className="product-name" style={{ height: "20%" }}>
          {/* <a href={url} target="_BLANK" onClick={trackBuyNowClick}>
            {name}
          </a> */}
          <Link
            // target="_blank"
            to={productPageLink}
            style={{ fontWeight: "bold", lineHeight: 1.2 }}
          >
            {name}
          </Link>
        </div>
        <div className="product-price" style={{ height: "10%" }}>
          {currency}
          {price}
        </div>
        <div
          className="product-buy-button"
          style={{
            height: "10%",
            // marginTop: "-10px",
            // height: "10%",
          }}
        >
          <a
            href={url}
            // target="_BLANK"
            onClick={trackBuyNowClick}
          >
            <Button type="primary" htmlType="submit" className="buy-button">
              Buy Now
            </Button>
          </a>
        </div>

        <Row style={{ height: "10%" }}>
          <Col xs={12}>
            <div className="product-history-button">
              {!productPageLink ? null : (
                <Link
                  // target="_blank"
                  to={productPageLink}
                  style={{ lineHeight: 2.5, fontWeight: "bold" }}
                >
                  Price History
                </Link>
              )}
            </div>
          </Col>
          <Col xs={12}>
            <div className="product-favourite">
              {showAddRemoveFavButton ? (
                <ButtonFavouriteProduct
                  website={website}
                  productId={productId}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
}

const mapStateToProps = (state) => ({
  // productInfo: state.product.product.data || {},
  // menu: state.generic.menu.data || {},
});

const mapDispatchToProps = (dispatch) => ({
  buyNowClickRequest: (data) => dispatch(actions.buyNowClickRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateProductSmallView);
